<template>
    <v-container class="ma-0 pa-0 mt-3">
        <!-- <v-row> -->
        <v-text-field solo-inverted :class="buttonClass" :dark="dark" :disabled="disableTextBox" v-model="v" hide-details />
        <!-- </v-row> -->
        <v-row class="ma-0 pa-0">
            <v-col cols="4" class="ma-0 pa-0">
                <v-btn :height="buttonHeight" width="100%" :dark="dark" class="text-h6" :class="buttonClass" tile @click="add('1')">1</v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
                <v-btn :height="buttonHeight" width="100%" :dark="dark" class="text-h6" :class="buttonClass" tile @click="add('2')">2</v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
                <v-btn :height="buttonHeight" width="100%" :dark="dark" class="text-h6" :class="buttonClass" tile @click="add('3')">3</v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
                <v-btn :height="buttonHeight" width="100%" :dark="dark" class="text-h6" :class="buttonClass" tile @click="add('4')">4</v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
                <v-btn :height="buttonHeight" width="100%" :dark="dark" class="text-h6" :class="buttonClass" tile @click="add('5')">5</v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
                <v-btn :height="buttonHeight" width="100%" :dark="dark" class="text-h6" :class="buttonClass" tile @click="add('6')">6</v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
                <v-btn :height="buttonHeight" width="100%" :dark="dark" class="text-h6" :class="buttonClass" tile @click="add('7')">7</v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
                <v-btn :height="buttonHeight" width="100%" :dark="dark" class="text-h6" :class="buttonClass" tile @click="add('8')">8</v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
                <v-btn :height="buttonHeight" width="100%" :dark="dark" class="text-h6" :class="buttonClass" tile @click="add('9')">9</v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
                <v-btn :height="buttonHeight" width="100%" :dark="dark" class="text-h6" :class="buttonClass" tile @click="remove"><v-icon>mdi-backspace</v-icon></v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
                <v-btn :height="buttonHeight" width="100%" :dark="dark" class="text-h6" :class="buttonClass" tile @click="add('0')">0</v-btn>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
                <v-btn :disabled="!canDo || addOrRemove" :height="buttonHeight" width="100%" :dark="dark" class="text-h6" :class="buttonClass" tile @click="ok">
                    <v-icon large>mdi-check</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="addOrRemove" class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
                <v-btn :disabled="!canDo" :height="buttonHeight" width="100%" :dark="dark" class="text-h6 error" :class="buttonClass" tile @click="emitToRemove">Remove</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
                <v-btn :disabled="!canDo" :height="buttonHeight" width="100%" :dark="dark" class="text-h6 success" :class="buttonClass" tile @click="emitToAdd">Add</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'BT-Numpad',
    data: function() {
        return {
            v: '',
        }
    },
    props: {
        addOrRemove: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: null
        },
        buttonHeight: {
            type: String,
            default: '75'
        },
        dark: {
            type: Boolean,
            default: null
        },
        disableTextBox: {
            type: Boolean,
            default: false
        },
        value: {
            type: Number,
            default: 0
        },
    },
    mounted() {
        if (this.value !== 0) {
            this.v = this.value.toString();
        }
    },
    computed: {
        canDo() {
            return !Number.isNaN(this.v);
        },
        resNum() {
            return Number.parseInt(this.v);
        }
    },
    methods: {
        add(much) {
            this.v = (this.v || '') + much;

            // this.updateNumber(res);
        },
        emitToAdd() {
            this.$emit('add', this.resNum);
            this.v = null;
        },
        emitToRemove() {
            this.$emit('remove', this.resNum);
            this.v = null;
        },
        ok() {
            // $emit('ok', resNum)
            this.$emit('ok', this.resNum);
            this.v = null;
        },
        updateNumber(res) {
            //attempt to convert to number
            var nRes = Number.parseInt(res);

            if (Number.isInteger(nRes)) {
                this.$emit('ok', nRes);
                this.v = res;
            }
        },
        remove() {
            if (this.v != null && this.v.length > 0) {
                if (this.v.length == 1) {
                    this.v = null;
                }
                else {
                    this.v = this.v.substring(0, this.v.length - 1);
                    //this.updateNumber(this.v.substring(0, this.v.length - 1));
                }
            }
        }
    }
}
</script>